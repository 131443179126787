import { DeniedPostPage } from '@amzn/ring-neighbors-contentful-gql-schema';
import { forwardRef, Ref, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
  getViewFromRoutePath,
  ItemType,
  useNeighborsWebClickEventStreamDispatcher,
  useNeighborsWebViewEventStreamDispatcher,
} from 'src/components/App/hooks';
import { config } from 'src/config';
import { useAuth, useFeature } from 'src/context';
import { EventMediaGallery } from 'src/shared/components/EventMediaGallery';
import { useIsSameAgency } from 'src/shared/hooks';
import { AUTO_DECLINE, DEFAULT_CONTENTFUL_CATEGORY } from 'src/shared/utils';
import { PostStatus } from 'src/types';
import { FeedEventCardBody } from './FeedEventCardBody';
import { FeedEventCardHeader } from './FeedEventCardHeader';
import { hoverStyles } from '../Card';
import { ConfirmationModal } from '../ConfirmationModal';
import { DeniedPostFaqModal } from '../DeniedPostFaqModal';
import { DeniedPostModal } from '../DeniedPostModal';
import {
  EventCard,
  EventCardFooter,
  EventCardFooterProps,
  EventCardProps,
} from '../EventCard';
import { EventCardCallout } from '../EventCard/EventCardCallout';
import { EventCardResolvedModal } from '../EventCard/EventCardResolvedModal';

export interface FeedEventCardProps
  extends Omit<EventCardProps, 'children'>,
    EventCardFooterProps {
  isActive?: boolean;
  dispatchEventStreamClick?: (
    item: ItemType,
    tags?: string[],
    options?: object,
  ) => Promise<void>;
  handleResolvePostClick?: () => void;
  handleReSchedulePostClick?: (id: string, scheduledFor: string) => void;
  handleEditScheduledPostClick?: (
    id: string,
    title: string,
    description: string,
  ) => void;
  showDeviceBanner: boolean;
  denialReasons?: DeniedPostPage[];
}

const { NEIGHBORS_COMMUNITY_GUIDELINES_URL } = config;

const FeedEventCard = (
  {
    event,
    isActive,
    isVoteLoading,
    onClick,
    onComment,
    onDownvote,
    onUpvote,
    dispatchEventStreamClick,
    handleResolvePostClick,
    showDeviceBanner,
    denialReasons,
    handleReSchedulePostClick,
    handleEditScheduledPostClick,
    ...props
  }: FeedEventCardProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { isPending } = useAuth();

  const enableResolveSwitch = useFeature('ENABLE_RESOLVE_SWITCH');
  const enablePostCallout = useFeature('ENABLE_POST_CALLOUT');
  const [deniedModalIsOpen, toggleDeniedModalIsOpen] = useToggle(false);
  const [deniedFaqModalIsOpen, toggleDeniedFaqModalIsOpen] = useToggle(false);
  const { id, is_owned, owner, details } = event;
  const { user_name, type, profile_url } = owner!;
  const {
    media,
    publisher_data,
    disable_reactions,
    disable_content_ellipsis,
    disable_sharing,
    is_commentable,
    hide_category,
    details_url,
    category,
    subcategories,
  } = details!;

  const ownerHeaderProps = {
    isOwned: is_owned,
    ownerUsername: user_name,
    ownerType: type,
    profileUrl: profile_url || undefined,
  } as const;

  const denialReason = useMemo(
    () =>
      denialReasons &&
      (category === AUTO_DECLINE
        ? denialReasons.find(
            (item) => item?.category === DEFAULT_CONTENTFUL_CATEGORY,
          )
        : denialReasons.find(
            (item) => item?.category === [category, subcategories[0]].join(':'),
          )),
    [denialReasons, subcategories, category],
  );

  const shouldShowFooter = useMemo(
    () =>
      event.status !== PostStatus.PENDING &&
      event.status !== PostStatus.DENIAL &&
      (is_commentable === true ||
        disable_sharing === false ||
        disable_reactions === false),
    [is_commentable, disable_sharing, disable_reactions, event.status],
  );

  const location = useLocation();
  const dispatchViewPostDeniedReason = useNeighborsWebViewEventStreamDispatcher(
    'nhWeb_postDeniedReason',
  );
  const dispatchViewFaq = useNeighborsWebViewEventStreamDispatcher(
    'nhWeb_seeFaqDeniedPost',
  );
  const dispatchClick = useNeighborsWebClickEventStreamDispatcher(
    'nhWeb_postDeniedReason',
  );

  const calloutClickHandler = () => {
    if (event.status === PostStatus.DENIAL) {
      dispatchEventStreamClick?.('nhWeb_clickDeniedBanner', [], {
        itemType: 'button',
      });
      toggleDeniedModalIsOpen();
    }
  };
  const isSameAgency = useIsSameAgency(owner?.agency_id);

  const onSeeGuidelines = useCallback(() => {
    const denialCode = denialReason?.category || '';
    dispatchClick?.('nhWeb_seeGuidelinesDeniedPage', [denialCode], {
      itemType: 'button',
    });
    const seeGuidelinesURL =
      denialReason?.guidelinesCta?.url || NEIGHBORS_COMMUNITY_GUIDELINES_URL;
    window.open(seeGuidelinesURL, '_blank');
  }, [denialReason?.guidelinesCta?.url, dispatchClick, denialReason?.category]);

  return (
    <EventCard
      {...props}
      ref={ref}
      event={event}
      color="neutral.500"
      hoverable
      sx={isActive ? hoverStyles : undefined}
      mb={4}
      handleResolvePostClick={handleResolvePostClick}
      handleReSchedulePostClick={handleReSchedulePostClick}
      handleEditScheduledPostClick={handleEditScheduledPostClick}
    >
      {({ dropdownOptions, resolveModalOptions }) => (
        <>
          <FeedEventCardHeader
            dropdownOptions={dropdownOptions}
            ownerHeaderProps={{ ...ownerHeaderProps }}
            publisherData={publisher_data!}
          />
          {media && (
            <EventMediaGallery
              borderRadius="regular"
              eventId={id}
              media={media}
              onMediaClick={onClick}
              showIndex
              thumbnailType="dashes"
              showDeviceBanner={showDeviceBanner}
            />
          )}
          <FeedEventCardBody
            onClick={
              event.status !== PostStatus.DENIAL &&
              event.status !== PostStatus.SCHEDULED
                ? onClick
                : () => {}
            }
            // onClick={onClick}
            cursor="pointer"
            disableClamp={disable_content_ellipsis!}
            hideTags={hide_category!}
            publisherData={publisher_data!}
            detailsUrl={details_url!}
            children={
              enablePostCallout &&
              event.status && (
                <EventCardCallout
                  data-testid="event-card-callout"
                  status={event.status}
                  clickHandler={denialReason && calloutClickHandler}
                  event={event}
                />
              )
            }
          />
          {shouldShowFooter && (
            <EventCardFooter
              isVoteLoading={isVoteLoading}
              onComment={() => {
                if (
                  isPending === false &&
                  dispatchEventStreamClick !== undefined
                )
                  dispatchEventStreamClick('nhWeb_commentButton');
                if (onUpvote) onComment?.();
              }}
              onDownvote={onDownvote}
              onUpvote={() => {
                if (
                  isPending === false &&
                  dispatchEventStreamClick !== undefined
                )
                  dispatchEventStreamClick('nhWeb_helpfulButton');
                if (onUpvote) onUpvote?.();
              }}
              dispatchEventStreamClick={dispatchEventStreamClick}
              disableShare={disable_sharing!}
              disableUpvote={disable_reactions!}
            />
          )}
          {enableResolveSwitch && (is_owned || isSameAgency) && (
            <>
              <EventCardResolvedModal {...resolveModalOptions.resolve} />
              <ConfirmationModal
                {...resolveModalOptions.unresolve}
                confirmationText="Marking as unresolved will clear the update from your post."
                headerText="Incident Status"
                cancelText="Cancel"
                submitText="Unresolve"
                modalTitleText="Are you sure?"
                hasWarning={false}
              />
            </>
          )}
          {denialReason && (
            <DeniedPostModal
              isOpen={deniedModalIsOpen}
              imageUrl={denialReason.icon?.url || ''}
              headerText={denialReason.header as string}
              bodyText={denialReason.reason?.json}
              title={denialReason.title || ''}
              primaryBtnText={denialReason.guidelinesCta?.text || ''}
              secondaryBtnText={denialReason.faqCtaText || ''}
              onClose={() => toggleDeniedModalIsOpen()}
              onOpen={() => {
                dispatchViewPostDeniedReason?.([], {
                  friendlyName: 'NHWEB Denied Post Reason',
                  referrer: getViewFromRoutePath(location.pathname),
                  referringItem: 'nhWeb_postDeniedBanner',
                  referringAction: 'click',
                });
              }}
              onPrimaryClick={onSeeGuidelines}
              onSecondaryClick={() => {
                toggleDeniedModalIsOpen();
                toggleDeniedFaqModalIsOpen();
                const denialCode = denialReason?.category || '';
                dispatchClick?.('nhWeb_seeFaqDeniedPage', [denialCode], {
                  itemType: 'button',
                });
              }}
            />
          )}
          {denialReason && denialReason?.faq && (
            <DeniedPostFaqModal
              isOpen={deniedFaqModalIsOpen}
              onClose={() => toggleDeniedFaqModalIsOpen()}
              header={denialReason.faq.header || ''}
              iconUrl={denialReason.faq.icon?.url || ''}
              title={denialReason.faq.title || ''}
              subtitle={denialReason.faq.subtitle || ''}
              richTextContent={denialReason.faq.description?.json}
              onOpen={() => {
                dispatchViewFaq?.([], {
                  friendlyName: 'NHWEB See FAQ for Denied Post',
                  referrer: 'nhWeb_postDeniedReason',
                  referringItem: 'nhWeb_seeFaqDenialPage',
                  referringAction: 'click',
                });
              }}
            />
          )}
        </>
      )}
    </EventCard>
  );
};

const RefForwardingFeedEventCard = forwardRef(FeedEventCard);

export { RefForwardingFeedEventCard as FeedEventCard };
