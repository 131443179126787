import Bugsnag from '@bugsnag/js';
import { getISOTimestamp } from 'src/apollo/utils';
import { getUserIDHmacToken } from 'src/auth';
import { isLocalBuild } from 'src/utils';
import { katalLogger } from './katal';

type Level = 'info' | 'debug' | 'warn' | 'error';
type Severity = 'info' | 'error' | 'warning';

const logIf =
  (level: Level) =>
  (condition: boolean, message: string, context?: Record<string, any>) => {
    if (isLocalBuild) {
      console[level](message);
    }

    if (!condition) {
      return;
    }

    katalLogger[level](message, context);
  };

export const logErrorIf = logIf('error');

export const logError = (message: string, context?: Record<string, any>) => {
  if (isLocalBuild) {
    console.error(message);
  }

  katalLogger.error(message, context);
};

export const logInfo = (message: string, context?: Record<string, any>) => {
  if (isLocalBuild) {
    console.info(message);
  }
  katalLogger.info(message, context);
};

export const logBugsnag = (severity: Severity, message: string) => {
  Bugsnag.notify(message, function (event) {
    event.severity = severity;
  });
};

export const getEventSecurityLogContext = (context: Record<string, any>) => {
  const timestamp = getISOTimestamp();
  const authMethod = 'OAuth';
  const hashedUserID = getUserIDHmacToken();

  context = {
    timestamp,
    authMethod,
    hashedUserID,
    ...context,
  };

  return context;
};

export const logContentSecurityPolicyViolation = (
  event: SecurityPolicyViolationEvent,
) => {
  const message = `Content Security Policy Violation of ${event.violatedDirective}`;

  const context = {
    blockedURI: event.blockedURI,
    disposition: event.disposition,
    documentURI: event.documentURI,
    effectiveDirective: event.effectiveDirective,
    statusCode: event.statusCode,
    sourceFile: event.sourceFile,
    violatedDirective: event.violatedDirective,
  };

  logError(message, context);
};
