import { useSearchParam } from 'src/hooks';
import type { NonNullableAlertArea } from 'src/types';

export const locationIdSearchParam = 'location_id';

export const useAlertAreaIdByRingLocation = (
  alertAreas: NonNullableAlertArea[],
) => {
  const locationId = useSearchParam(locationIdSearchParam);

  if (!locationId) {
    return;
  }

  const alertAreaByRingLocation = alertAreas.find(({ details }) =>
    details.ring_location_ids?.some((id) => id === locationId),
  );

  return alertAreaByRingLocation?.id;
};
