import { Features, useFeature } from 'src/context';
import type { FunctionComponent, ReactElement } from 'react';

type Props = {
  flagName: Features;
};

/**
 * Access gate which renders any children but only if the feature flag is enabled
 *
 * @example - Button only renders if flag is enabled
 * <FeatureFlagRequired flagName="SHOW_BUTTON">
 *   <Button>Click Me</Button>
 * </FeatureFlagRequired>
 */
export const FeatureFlagRequired: FunctionComponent<Props> = ({
  children,
  flagName,
}) => {
  const featureFlag = useFeature(flagName);
  return featureFlag ? (children as ReactElement) : null;
};
