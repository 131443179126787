import { border } from 'polished';
import { useEventCategories } from 'src/shared/context';
import { Box, BoxBasedComponentProps } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import type { EventCategory } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export type IncidentCategoriesProps = BoxBasedComponentProps<
  'div',
  {
    activeCategory?: EventCategory;
    categoryBorderType?: CategoryProps['borderType'];
    onCategoryClick?: CategoryProps['onClick'];
  }
>;

export const IncidentCategories = ({
  categoryBorderType,
  onCategoryClick,
  activeCategory,
  ...props
}: IncidentCategoriesProps) => {
  const { categories } = useEventCategories();

  return (
    <Flex {...props} flexDirection="column">
      {categories.map((category) => (
        <Category
          key={category.id}
          borderType={categoryBorderType}
          category={category}
          isActive={category.id === activeCategory?.id}
          onClick={onCategoryClick}
        />
      ))}
    </Flex>
  );
};

export interface CategoryProps {
  borderType?: 'bottom' | 'none';
  category: EventCategory;
  isActive?: boolean;
  onClick?: (category: EventCategory) => void;
}

const Category = (props: CategoryProps) => {
  const {
    category: { title, text },
  } = props;

  const styles = getCategoryStyles(props);

  return (
    <Box>
      <Flex
        {...styles}
        flexDirection="row"
        alignItems="center"
        mb={{ _: 4, m: 2 }}
        pl={6}
      >
        <Box mr="auto" width={0.8}>
          <Box textStyle="body-large-medium">{title}</Box>
          <Box textStyle="body-normal-light">{text}</Box>
        </Box>
        <Box>
          <Icon name="chevron-right-2" mr={2} size={18} color="neutral.500" />
        </Box>
      </Flex>
    </Box>
  );
};

export const getCategoryStyles = ({
  category,
  isActive,
  onClick,
  borderType,
}: CategoryProps) => {
  const border = getBorder(borderType);

  const base = {
    ...border,
    bg: 'white',
    flexDirection: 'column',
    p: 4,
    transition: 'border-color 200ms ease-in-out',
  };

  return [
    base,
    borderType !== 'none' && {
      _notLast: {
        mb: 3,
      },
    },
    isActive && {
      borderColor: 'primary.500',
      borderWidth: '1px',
    },
    onClick && {
      cursor: 'pointer',
      _hover: { borderColor: 'primary.hover', borderWidth: '1px' },
      onClick: () => onClick(category),
    },
  ]
    .filter(Boolean)
    .reduce((acc, s) => ({ ...acc, ...s }), {});
};

const getBorder = (borderType?: CategoryProps['borderType']) => {
  if (borderType === 'none') {
    return { border: 'none' };
  }

  const radius = {
    borderRadius: 'round',
  };

  if (borderType === 'bottom') {
    return { ...border('bottom', '1px', 'solid', 'borders'), ...radius };
  }

  return { ...border('1px', 'solid', 'borders'), ...radius };
};
