import { getCLS, getFCP, getFID, getLCP, getTTFB, Metric } from 'web-vitals';
import { logInfo } from './logs';

const sendWebVitalsToLoggers = (metric: Metric) => {
  const { name, value } = metric;
  const rounded = Math.round(value);

  const message = `Web Vitals: ${name}:${rounded}`;

  logInfo(message, metric);
};

export const reportWebVitals = () => {
  getCLS(sendWebVitalsToLoggers);
  getFID(sendWebVitalsToLoggers);
  getFCP(sendWebVitalsToLoggers);
  getLCP(sendWebVitalsToLoggers);
  getTTFB(sendWebVitalsToLoggers);
};
