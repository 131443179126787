import { Config } from './types';

export const configsUnification: Config = {
  CONSENT_MANAGER_SCRIPT_URL:
    'https://d1ber8az9zrhox.cloudfront.net/common/js/consent-manager-50632c5442c34437f12470bcba5f10ce.js',
  HEAP_ID: '1144240245',
  KATAL_API_URL:
    'https://26jqs1tayk.execute-api.us-east-1.amazonaws.com/prod/v1/log',
  MAPBOX_API_KEY:
    'pk.eyJ1IjoicmluZ21hcHMiLCJhIjoiY2pmYTl0aXZvMTM3NjQ0cWgzdnJiY2g2eSJ9.6DI54yHi79n28q4ZN8tRzQ',
  NEIGHBORS_URL: 'https://neighbors.dev.ring.com',
  RING_ACCOUNT_URL: 'https://account.dev.ring.com',
  RING_DASHBOARD_URL: 'https://account.dev.ring.com/account/dashboard',
  RING_URL: 'https://www.dev.ring.com',
  ENABLE_UPLOAD_FROM_RING_HISTORY: true,
  ENABLE_RESOLVE_SWITCH: true,
  ENABLE_DEALS_BUTTON: true,
  ENABLE_MARKDOWN: true,
  ENABLE_POST_CALLOUT: true,
  ENABLE_BOR_SUBMIT_VIDEO: true,
  ENABLE_UA_HEADERS: true,
  ENABLE_UNIFIED_MY_POSTS_PAGE: true,
  ENABLE_NHWEB_NPSS_FEED_QUERY_UNIFICATION: true,
  CONTENTFUL_API_BASE_URL: 'https://graphql.contentful.com',
  CONTENTFUL_SPACE_ID: 'cpcn7rmzq5g6',
  CONTENTFUL_API_TOKEN: '0xLWPKJ8M7w9GjQOFvqNjwoeVyn98wQhPIrzDisquFE',
  CONTENTFUL_SPACE_ENVIRONMENT: 'dev',
  EVENT_STREAM_API_BASE_URL: 'https://eventstream.dev.ring.com',
  SHOW_SEND_SMS: true,
  NPSS_BASE_URL: 'https://publicsafety.dev.ring.com',
  NPSS_REDIRECTION_URL: 'localnpss.dev.ring.com',
  NPSS_EMAIL_SETTINGS_URL:
    'https://settings.neighbors.dev.ring.com/settings/email',
  NPSS_HELP_URL: 'https://help.publicsafety.ring.com/',
  NEIGHBORS_COMMUNITY_GUIDELINES_URL:
    'https://support.ring.com/hc/en-us/articles/115004851266-Neighbors-by-Ring-Community-Guidelines',
  BUGSNAG_API_KEY: '62132b4fa9189bec66bd1602ab5c43e0',
  BUGSNAG_NOTIFY_URL: 'https://events.bugsnag.prod.ring.com',
  BUGSNAG_SESSIONS_URL: 'https://sessions.bugsnag.prod.ring.com',
  BOR_VIDEO_SUBMISSION_EMAIL: 'submissions-dev@ring.com',
  LOCATION_LEARN_MORE_URL:
    'https://ring.com/support/articles/e0plk/Who-Can-See-Your-Post',
};
