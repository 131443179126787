import { FeedFiltersState } from 'src/components/Feed/components/FeedSubNavbar/types';

export const STORED_FEED_FILTERS_KEY = 'npssFeedFilters';

export const getStoredFeedFiltersState = () => {
  const storedFeedFiltersString = window.sessionStorage.getItem(
    STORED_FEED_FILTERS_KEY,
  );
  if (storedFeedFiltersString) {
    return JSON.parse(storedFeedFiltersString) as FeedFiltersState;
  }
  return null;
};
