import {
  defaultBorderBottom,
  Flex,
  FlexProps,
  navbarHeight,
} from '@amzn/ring-ui-react-components';
import { useHasWebViewUserAgent } from 'src/hooks';

export const SubNavbar = (props: FlexProps) => {
  const isWebView = useHasWebViewUserAgent();

  return (
    <Flex
      bg="white"
      height={subNavbarHeight}
      position="sticky"
      px={{ base: 6, m: 10 }}
      py={4}
      top={isWebView ? 0 : navbarHeight}
      zIndex="docked"
      align="center"
      {...props}
      {...defaultBorderBottom}
    />
  );
};

export const subNavbarHeight = 14;
