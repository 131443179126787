import GET_FEED from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/getFeed.graphql';
import GET_FEED_BY_ALERT_AREA_ID_AND_INPUT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/getFeedByAlertAreaIdAndInput.graphql';
import { NetworkStatus } from '@apollo/client';
import { useIsNpssUser } from 'src/auth';
import { useAlertAreaState, useFeature } from 'src/context';
import { shouldIgnoreCommentCountError, useQueryWithBoundary } from 'src/hooks';
import { UAParser } from 'ua-parser-js';
import type {
  FeedFilters,
  GetFeedByAlertAreaIdAndInputQuery,
  GetFeedByAlertAreaIdAndInputQueryVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

// https://github.com/apollographql/apollo-client/blob/master/src/core/networkStatus.ts
// when the user changes the selected alert area apollo sets the networkStatus to setVariables (because the query variables change)
// we don't use the built-in `loading` flag because it includes many statuses we don't want to show a loading spinner for (like the fetchMore status)
const loadingStatuses = [NetworkStatus.loading, NetworkStatus.setVariables];

const userAgent = new UAParser();
const RING_USER_AGENT_DEVICE_OS = userAgent.getOS().name;
const RING_USER_AGENT_APP_BRAND = 'neighbors';
const RING_USER_AGENT_DEVICE_VERSION = '0.0.0';

export const useGetFeedBySelectedAlertAreaIdAndInputQuery = (
  input: FeedFilters | undefined = {},
) => {
  const { selectedAlertArea } = useAlertAreaState();
  const isNpssUser = useIsNpssUser();
  const enableUAHeaders = useFeature('ENABLE_UA_HEADERS');
  const enableUnifiedQuery = useFeature(
    'ENABLE_NHWEB_NPSS_FEED_QUERY_UNIFICATION',
  );

  const {
    networkStatus: npssNetworkStatus,
    fetchMore: npssFetchMore,
    ...npssFeedResult
  } = useQueryWithBoundary(GET_FEED, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input,
      alert_area_id: '',
    },
    skip: enableUnifiedQuery || !isNpssUser,
  });

  const { networkStatus, fetchMore, ...result } = useQueryWithBoundary<
    GetFeedByAlertAreaIdAndInputQuery,
    GetFeedByAlertAreaIdAndInputQueryVariables
  >(
    GET_FEED_BY_ALERT_AREA_ID_AND_INPUT,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        alert_area_id: selectedAlertArea!.id,
        input,
      },
      skip: enableUnifiedQuery || isNpssUser,
      context: {
        headers: enableUAHeaders
          ? {
              'x-ring-useragent-deviceos': RING_USER_AGENT_DEVICE_OS,
              'x-ring-useragent-appbrand': RING_USER_AGENT_APP_BRAND,
              'x-ring-useragent-deviceversion': RING_USER_AGENT_DEVICE_VERSION,
            }
          : {},
      },
    },
    shouldIgnoreCommentCountError,
  );

  const loading =
    loadingStatuses.includes(networkStatus) ||
    loadingStatuses.includes(npssNetworkStatus);

  if (isNpssUser) {
    return {
      ...npssFeedResult,
      fetchMore: npssFetchMore,
      loading,
      networkStatus: npssNetworkStatus,
    };
  }

  return { ...result, fetchMore, loading, networkStatus };
};
