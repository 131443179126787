import {
  NavbarItem,
  NavbarLink,
  NavbarNav,
  NavbarNavCollapse,
  NavbarUserCollapse,
} from '@amzn/ring-ui-react-components';
import { useTranslation } from 'react-i18next';
import { useOpacityTransition } from 'src/hooks';
import { Icon } from 'src/shared/components/Icon';
import {
  helpCenterNeighboprsUrl,
  hrefDownloadAppAttribution,
} from 'src/shared/utils';
import { getLoginUrl } from 'src/utils';
import {
  NavbarBase,
  navbarNavCollapseTestProps,
  navbarUserCollapseTestProps,
  RingShopNavbarItem,
} from './NavbarBase';

// TODO: items on the left are aligned to the brand logo by eye, search for a better styling solution
export const UnauthenticatedNavbarDesktop = () => {
  const { t } = useTranslation(['components.navbar', 'common'], {
    useSuspense: false,
  });
  const transitionProps = useOpacityTransition();

  return (
    <NavbarBase>
      <NavbarNavCollapse
        {...navbarNavCollapseTestProps}
        ml={3}
        mr="auto"
        pt={{ m: 1 }}
      >
        <NavbarNav {...transitionProps}>
          <NavbarItem>
            <NavbarLink href={hrefDownloadAppAttribution}>
              {t('get-the-app')}
            </NavbarLink>
          </NavbarItem>
          <NavbarItem>
            <NavbarLink href={hrefDownloadAppAttribution}>
              {t('learn-more')}
            </NavbarLink>
          </NavbarItem>
          <NavbarItem>
            <NavbarLink href={helpCenterNeighboprsUrl}>
              {t('help-center')}
            </NavbarLink>
          </NavbarItem>
        </NavbarNav>
      </NavbarNavCollapse>
      <NavbarUserCollapse {...navbarUserCollapseTestProps} height="100%">
        <NavbarNav {...transitionProps}>
          <NavbarItem>
            <NavbarLink href={getLoginUrl()} textStyle="footnote-xsmall-medium">
              <Icon
                aria-hidden="true"
                focusable="false"
                mb={1}
                name="account"
                size={20}
              />
              {t('login', { ns: 'common' })}
            </NavbarLink>
          </NavbarItem>
          <RingShopNavbarItem />
        </NavbarNav>
      </NavbarUserCollapse>
    </NavbarBase>
  );
};
