import GET_NPSS_ANNOUNCEMENT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/npss/npssAnnouncement.graphql';
import { useIsNpssUser } from 'src/auth';
import { useFeature } from 'src/context';
import { useQueryWithBoundary } from 'src/hooks';
import { UAParser } from 'ua-parser-js';
import type {
  NpssAnnouncementQuery,
  NpssAnnouncementQueryVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

const userAgent = new UAParser();
const RING_USER_AGENT_DEVICE_OS = userAgent.getOS().name;
const RING_USER_AGENT_APP_BRAND = 'neighbors';
const RING_USER_AGENT_DEVICE_VERSION = '0.0.0';

export const useGetAnnouncement = () => {
  const enableUAHeaders = useFeature('ENABLE_UA_HEADERS');

  const isNpssUser = useIsNpssUser();

  const { data } = useQueryWithBoundary<
    NpssAnnouncementQuery,
    NpssAnnouncementQueryVariables
  >(GET_NPSS_ANNOUNCEMENT, {
    notifyOnNetworkStatusChange: true,
    variables: {
      type: 'post-sign-in',
    },
    skip: !isNpssUser,
    context: {
      headers: enableUAHeaders
        ? {
            'x-ring-useragent-deviceos': RING_USER_AGENT_DEVICE_OS,
            'x-ring-useragent-appbrand': RING_USER_AGENT_APP_BRAND,
            'x-ring-useragent-deviceversion': RING_USER_AGENT_DEVICE_VERSION,
          }
        : {},
    },
  });

  return data?.npssAnnouncement;
};
