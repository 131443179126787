import { randomBytes, createHash } from 'crypto';

export function getIntersystemID(doorbotId: number): string {
  const seed = randomBytes(128).toString('utf8');
  const rawValue = doorbotId.toString() + seed;

  const hash = createHash('sha256').update(rawValue, 'utf8').digest();
  const hexValue = hash.toString('hex');
  return hexValue.length > 32
    ? hexValue.slice(0, 32)
    : hexValue.padStart(32, '0');
}

export enum CameraAngle {
  None = 'NONE',
  Back = 'BACK',
  Front = 'FRONT',
  Left = 'LEFT',
  Right = 'RIGHT',
}
