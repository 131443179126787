import KatalLogger, { Level } from '@amzn/katal-logger';
import { noop } from '@chakra-ui/utils';
import { config } from 'src/config';
import { isDeployedBuild } from 'src/utils';

// Katal logger reference: https://katal.corp.amazon.com/#/logger
// Api gateway url can be found at consumer site aws account -> api gateway -> stages -> prod -> invoke url
// Logs can be found at RingNeighborsConsumerSiteKatal<stage> aws account -> cloudwatch -> logs -> log groups -> RingNeighborsConsumerSiteKatalLogger-<stage>-RingNHConsumerSiteLoggerbetaKatalLoggerLogGroup
const { KATAL_API_URL } = config;

const getLogger = () => {
  if (isDeployedBuild) {
    return new KatalLogger({
      url: KATAL_API_URL,
      logThreshold: Level.INFO,
    });
  }

  return {
    debug: noop,
    info: noop,
    warn: noop,
    error: noop,
    fatal: noop,
  } as unknown as KatalLogger;
};

export const katalLogger = getLogger();
