import styled from 'styled-components';
import { Icon, IconProps } from '../Icon';

export type LoadingProps = Omit<IconProps, 'name'>;

export const Loading = (props: LoadingProps) => (
  <SpinningIcon size="2em" color="primary.500" {...props} name="spinner">
    <title>Loading...</title>
  </SpinningIcon>
);

const SpinningIcon = styled(Icon)`
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
