import {
  Cart,
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarLink,
  NavbarNavToggle,
  NavbarProps,
  Neighbors,
} from '@amzn/ring-ui-react-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AuthStatus } from 'src/auth';
import { ItemType as ItemTypeEventStream } from 'src/components/App/hooks';
import { useAuth, useFeature } from 'src/context';
import { AppRoutes } from 'src/routes';
import { Box, BoxBasedComponentProps } from 'src/shared/components/Box';
import { Flex } from 'src/shared/components/Flex';
import { Icon, IconName } from 'src/shared/components/Icon';

type NavbarBaseProps = NavbarProps & {
  iconSize?: number;
};

export const NavbarBase = ({
  children,
  iconSize,
  ...props
}: NavbarBaseProps) => (
  <Navbar
    {...props}
    data-testid="navbar"
    fixed
    justifyContent={{ base: 'flex-start' }}
    zIndex="navbar"
  >
    <NavbarNavToggle mr={2} iconSize={iconSize} />
    <NavbarBrand href={AppRoutes.Feed}>
      <Neighbors boxSize={{ base: 8, m: 10 }} mr={2} />
      <Box textStyle="heading-small-regular">Neighbors</Box>
    </NavbarBrand>
    {children}
  </Navbar>
);

export const RingShopNavbarItem = (props: {
  dispatchEventStreamClick?: (
    item: ItemTypeEventStream,
    tags?: string[],
  ) => Promise<void>;
}) => {
  const { t } = useTranslation('components.navbar', { useSuspense: false });
  const { authStatus, isPending } = useAuth();
  const { push } = useHistory();
  const enableDealsButton = useFeature('ENABLE_DEALS_BUTTON');
  const { dispatchEventStreamClick } = props;

  const isAuthenticated = authStatus === AuthStatus.authenticated;

  const linkProps = enableDealsButton
    ? { onClick: () => push(AppRoutes.Deals) }
    : { href: 'https://ring.com' };

  return (
    <NavbarItem
      onClick={() => {
        if (isPending) return;
        if (!isAuthenticated) return;
        if (!dispatchEventStreamClick) return;

        dispatchEventStreamClick('nhWeb_neighborsDeal');
      }}
      data-testid="ring-shop-navbar-item"
    >
      <NavbarLink
        {...linkProps}
        textStyle="footnote-xsmall-medium"
        data-testid="ring-shop-navbar-link"
      >
        <Cart mb={1} boxSize="20px" aria-hidden={true} />
        {enableDealsButton ? t('deals') : t('ring-shop')}
      </NavbarLink>
    </NavbarItem>
  );
};

type ItemType = 'a' | 'div';

type NavButtonProps<T extends ItemType = 'div'> = BoxBasedComponentProps<
  T,
  { icon: IconName }
>;

export const NavMobileButton = <T extends ItemType = 'div'>({
  as,
  children,
  icon,
  ...props
}: NavButtonProps<T>) => (
  <Flex flex={1} justifyContent="flex-end" data-testid="nav-mobile-button">
    <Flex
      alignItems="center"
      as={as}
      color="inherit"
      cursor="pointer"
      flexDirection="column"
      justifyContent="center"
      px={4}
      py={2}
      textDecoration="none"
      {...props}
    >
      <Icon aria-hidden="true" focusable="false" size={20} name={icon} />
      <Box textStyle="footnote-xsmall-medium">{children}</Box>
    </Flex>
  </Flex>
);

export const navbarNavCollapseTestProps = {
  'data-testid': 'navbar-nav-collapse',
};

export const navbarUserCollapseTestProps = {
  'data-testid': 'navbar-user-collapse',
};
